'use client';

import Link from '@components/Link';
import { useMarket } from '@context/marketContext';
import type { ReactNode } from 'react';

const ProductListMoreLink = ({
    slug,
    children,
}: {
    slug?: string;
    children: ReactNode;
}) => {
    const locale = useMarket().state.market.locale;

    if (!slug || !children) return null;

    return (
        <Link
            locale={locale}
            href={slug}
            className="mt-1 whitespace-nowrap text-sm underline">
            {children}
        </Link>
    );
};

ProductListMoreLink.displayName = 'ProductListMoreLink';

export default ProductListMoreLink;
